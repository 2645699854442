<template>
  <div class="gettingstarted" id="gettingstarted">
    <div class="ui grid container faq">
      <div class="intro-content">
        <p class="path">Frequently Asked Questions > Getting Started</p>
        <h3><span>Getting</span> Started</h3>

        <h4 id="gts1">How do I register for a TayoCash account?</h4>
        <div class="faq-data">

          <div class="content">
            <p>a. Download the TayoCash app by scanning the QR or clicking the link below.</p>
            <div class="images">
              <a href="https://apps.apple.com/ph/app/tayocash/id1547566625" target="_blank"><img class="qr-code" src="@/assets/qr-ios.png" alt="Tayocash">
                <span>iOS</span>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.tayocash" target="_blank"><img class="qr-code" src="@/assets/qr-android.png" alt="Tayocash">
                <span>Android</span>
              </a>
            </div>
            <p>b. Enter your Mobile Number and Full Name to sign up and create a Basic Member account.</p>
            <img class="phone" src="@/assets/g1.svg" alt="Tayocash">

            <i>Important Reminder: A One-Time PIN (OTP) will be sent to your Mobile Number to complete the registration. Please make sure to use an active mobile number for registration.</i>

            <p>c. Proceed with the verification process to upgrade to a Semi-Verified or Fully Verified Member Account. Click <a class="link" href="/faq/verification">here</a> to know how to verify your Member Account.</p>
          </div>
        </div>

        <h4 id="gts2">Are minors allowed to register an account?</h4>
        <div class="faq-data second">

          <div class="content">
            <p>Yes, minors are allowed to register an account, with the following additional information to be required:</p>
            <img class="phone" src="@/assets/g2.svg" alt="Tayocash">
            <ul>
              <li>Guardian’s Name</li>
              <li>Guardian’s Mobile Number</li>
              <li>Relationship to Guardian</li>
              <li>Guardian’s Government-Issued ID</li>
            </ul>
          </div>
          <div class="buttons">
            <a href="/helpcenter" class="yellow">BACK</a>
            <a href="/faq/membership" class="white">NEXT</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'gettingstarted',
  wheight: window.innerHeight,
  data () {
    return {
      windowHeight: window.innerHeight,
      height: ''
    }
  },
  // watch: {
  //   wheight(newHeight) {
  //    this.height = newHeight
  //    console.log(newHeight);
  //   }
  // },

  watch: {
    windowHeight(newHeight, oldHeight) {
     this.height = `it changed to ${newHeight} from ${oldHeight}`;
    }
  },

  mounted() {
    this.height = window.innerHeight
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowHeight = window.innerHeight
    }
  }
}
</script>

<style scoped lang='scss'>
</style>
